/* Page Sizing */

.full {
    width:100vw;
}

/* Min-heights */
.full-page {
    min-height:100rem !important;;
}
.three-quarter-page{
    min-height: 60rem !important;;
}
.two-thirds-page {
    min-height: 50rem !important;
}
.half-page {
    min-height:35rem !important;
}
.third-page {
    min-height:22rem !important;
}
.quarter-page {
    min-height:15rem  !important;;
}
.eigth-page {
    min-height: 8rem  !important;;
}

/* Maximums */
.full-page-max {
    max-height:100rem !important;
}
.three-quarter-page-max {
    max-height: 60rem !important;
}
.two-thirds-page-max {
    max-height: 50rem !important;
}
.half-page-max {
    max-height:35rem !important;
}
.third-page-max {
    max-height:22rem !important;
}
.quarter-page-max {
    max-height:15rem !important;
}
.eigth-page-max {
    max-height: 8rem !important;
}
.sixteenth-page-max {
    max-height: 3.25vh!important;
}


/* Progress Bar Specific */
.ui.p2lgreen.progress .bar {
    background-color: #12d6b2;
}

/* Theme Colors */
.p2lgreen {
    background-color: #12d6b2;
}
.p2lgrey {
    background-color: #a2aab0;
}
.text-p2lgreen {
    color: #12d6b2 !important;
}
.p2lmedium-blue {
    background-color: #0500d6 ! important;
}
.text-p2lmedium-blue {
    color: #0500d6 ! important;
}
.p2llight-blue {
    background-color: #54D4F5 !important;
}
.text-p2llight-blue {
    color: #54D4F5 !important;
}
.p2lPurple {
    background-color: #582F82 !important;
}
.text-p2lPurple {
    color: #582F82 !important;
}
.p2ldark-blue {
    background-color: #00005A !important;
}
.text-p2ldark-blue {
    color: #00005A !important;
}
.diet {
    background-color: #2384D1 !important;
}
.consumption {
    background-color: #18BCD7 !important;
}
.transport {
    background-color: #20D8B4 !important;
}
.travel {
    background-color: #8DEACF !important;
}
.energy {
    background-color: #FE9331 !important;
}
.community {
    background-color: #EB6890 !important;
}

/* Element and Content */
.content-container {
    padding:2rem;
}
.full-h {
    height:100%;
}
.full-w {
    width:100%;
}
.invisible {
    display:none !important;
}
.footer{
    color: #FFFFFF;
    padding:2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.scrolling-right{
    overflow-y: auto;
}

.scrolling-right::-webkit-scrollbar{
    display:none;
}

.button-p2lgreen {
    background-color: #12d6b2 !important;
}
.white {
    color: #FFFFFF !important;
}
.footerlinks {
    color: #FFFFFF;
    white-space: nowrap;
}
.footerlinks:hover {
    color: #FFFFFF;
    text-decoration: none;
}
.button-grey {
    background-color: #a0eee0;
    padding-left: 2.5em;
    padding-right: 2.5em;
    font-size: 1.2em;
    color: #0c957c;
}
/* .header{
    padding:1rem;
} */
.ui.button-theme {
    background-color:white;
    padding-left: 3em;
    padding-right: 3em;
    font-size: 1.2em;
    color: rgb(0, 0, 90);
}
.menu-title {
    font-size:1.6rem;
}

.CompletedAction{
    margin-block-start: -0.5em;
    opacity: 0.8 !important;
}
.loginPage {
    padding-left:0!important;
}
.PieChart {
-webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 2rem;
  width: 100%;
  height: 85%;
}
.pieOuter {
    box-sizing: border-box;
    width: 50vw;
    height: 90vh;
    text-align: center;
}

.PieChartMini {
    -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      width: 10rem;
      height: 8rem;
      text-align: center;
}
.PieChartMini:first-child {
    display: inline-block;
}

.pieLabel {
    font-size:1em;
}
.centroid {
    font-size:1.1em;
}

.AreaChart {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    /* padding: 1rem; */
    width:85%;
    height: 65%;
    margin-top:8rem;
    float: left;
}

.BarChart {
    box-sizing: border-box;
    /* padding: 1rem; */
    width:15%;
    height: 65%;
    margin-top:8rem;
    float: left;
}

.twoCharts {
    width:100%;
    height: 100%;
}

.label {
    font-size:1em;
}
.label.sub {
    font-size:0.8em;
}


/* Custom Tooltip */
.tooltip-points{
    color:#FF991D;
}
.tooltip-emmissions {
    color: #00B5AD;
}
.tooltip-emmissions-reduction {
color: #20D8B4
}
.tooltip-card {
    width:170px !important;
}

/* Margin and Padding */
.sides2{
    padding-left:2rem;
    padding-right:2rem;
}
.top2{
    padding-top:2rem !important;
    padding-bottom:2rem !important;
}
.m0 {
    margin:0rem !important;
}
.m1 {
    margin:1rem !important;
}
.m2 {
    margin:2rem !important;
}
.m3 {
    margin:3rem !important;
}
.p-0 {
    padding: 0 !important;
}
.p-1 {
    padding:1em !important;
}
.p-2 {
    padding:2em !important;
}
.b-0 {
    padding-bottom: 0 !important;
}
.b-1 {
    padding-bottom:1em !important;
}
.b-2 {
    padding-bottom:2em !important;
}
.b-3 {
    padding-bottom:3em !important;
}
.t-0 {
    padding-top: 0 !important;
}
.t-1 {
    padding-top:1em !important;
}
.t-2 {
    padding-top:2em !important;
}
.t-3 {
    padding-top:3em !important;
}
.r-0 {
    padding-right: 0 !important;
}
.r-1 {
    padding-right:1em !important;
}
.r-2 {
    padding-right:2em !important;
}
.r-3 {
    padding-right:3em !important;
}
.l-0 {
    padding-left:0 !important;
}
.l-1 {
    padding-left:1em !important;
}
.l-2 {
    padding-left:2em !important;
}
.l-3 {
    padding-left:3em !important;
}

.tooltip{
    max-height: 15rem;
}


.actions {
    padding:2em;
    border: 2px solid #afeeee;
    border-radius: 1em;
}
.actions-carousel{
    padding-top:10%;
}
.action-buttons {
    left: 0;
    position: relative;
}

.ext-q {
    margin-bottom:1rem !important;
    padding-bottom:4rem !important;
}
.numberlabel {
    border-right:none !important;
    border-top:none !important;
    border-bottom:none !important;
}


.wide-form{
    padding-left: 3rem!important;
    width: 80%;
}
.wrapped{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

/* Mobile Classes */
@media screen and (max-width: 500px) {
    .pieOuter{
        width:350px;
        height: 350px;
    }
}
@media screen and (max-width: 768px) {
    .AreaChart {
        /* margin-left: -8rem; */
        margin:0;
        width: 100%;
        height: 100%;
    }
    .menu-title {
        font-size: 1.1rem;
    }
    .ui.form {
        padding-left: 0 !important;
    }
    .pieOuter{
        width:400px;
        height: 400px;
    }
    .recharts-wrapper svg{
        overflow: inherit !important;
        }
    .PieChart {
        width:100%;
        height:95%;
        /* margin-left: -8rem; */
        padding:0;
        margin:0;
        display: inline-block;
        overflow: auto;
    }
    .BarChart {
        margin-top: 0rem;
    }
    .stickyInfo {
        padding:0!important;
    }
    .action-buttons{
        margin-top:20%;
    }
    [class^="horizontalSlider"] {
        height:40vh;
    }
    .carousel {
        height:100%;
    }
    .mobile-twocharts {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .pieLabel{
        font-size:0.8em;
        color:#000;
    }
    .centroid {
        font-size:0.9em;
    }
    .action-buttons {
        bottom: 0;
    }
}

/*Adding Device Specific Hidden*/
/* Mobile */
@media only screen and (max-width: 767px) {
    [class*="mobile hidden"],
    [class*="tablet only"]:not(.mobile),
    [class*="computer only"]:not(.mobile),
    [class*="large screen only"]:not(.mobile),
    [class*="widescreen only"]:not(.mobile),
    [class*="or lower hidden"] {
      display: none !important;
    }
  }

  /* Tablet / iPad Portrait */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    [class*="mobile only"]:not(.tablet),
    [class*="tablet hidden"],
    [class*="computer only"]:not(.tablet),
    [class*="large screen only"]:not(.tablet),
    [class*="widescreen only"]:not(.tablet),
    [class*="or lower hidden"]:not(.mobile) {
      display: none !important;
    }
  }

  /* Computer / Desktop / iPad Landscape */
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    [class*="mobile only"]:not(.computer),
    [class*="tablet only"]:not(.computer),
    [class*="computer hidden"],
    [class*="large screen only"]:not(.computer),
    [class*="widescreen only"]:not(.computer),
    [class*="or lower hidden"]:not(.tablet):not(.mobile) {
      display: none !important;
    }
  }

  /* Large Monitor */
  @media only screen and (min-width: 1200px) and (max-width: 1919px) {
    [class*="mobile only"]:not([class*="large screen"]),
    [class*="tablet only"]:not([class*="large screen"]),
    [class*="computer only"]:not([class*="large screen"]),
    [class*="large screen hidden"],
    [class*="widescreen only"]:not([class*="large screen"]),
    [class*="or lower hidden"]:not(.computer):not(.tablet):not(.mobile) {
      display: none !important;
    }
  }

  /* Widescreen Monitor */
  @media only screen and (min-width: 1920px) {
    [class*="mobile only"]:not([class*="widescreen"]),
    [class*="tablet only"]:not([class*="widescreen"]),
    [class*="computer only"]:not([class*="widescreen"]),
    [class*="large screen only"]:not([class*="widescreen"]),
    [class*="widescreen hidden"],
    [class*="widescreen or lower hidden"] {
      display: none !important;
    }
  }
