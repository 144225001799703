
.graph {
  /* -webkit-box-sizing: border-box; */
  /* -moz-box-sizing: border-box; */
  box-sizing: border-box;
  padding: 10px;
  width: 800px;
  height: 800px;
  background-color: #fff;
}

/* .GButton {
  padding-top: 17px
} */
/* 
.StepForm {
  width: 800px;
  height: 800px;
  font-size: 200px;
  padding: 50px
}
.popup {
  padding: 10px;
  width: 600px;
  height: 1600px;
  background-color: #fff;
}

.ui.borderless.menu {
  margin: 0;
}

.logo {
  height: 5em;
}

.small-logo {
  margin-left: 100px;
  height: 10vmin;
  margin-right: 100px;
}

.large-logo {
  height: 25vmin;
}


.ui.container1 {
  min-height: 50vh;
  max-width: 250vh;
  padding-left: 10vh;
  background-color: #12d6b2;
}

.ui.grid1 {
  max-width: 300vh;
  display: center;
  align-content: center;
  padding-left: 200px;
  min-height: 30vh;
  padding-top: 50px;
  background-color: #18BCD7;
}
.ui.vertically.divided.stackable.grid {
  display: flex;
  max-width: 300vh;
  flex-wrap: wrap;
}

.header1 {
  font-family: Futura;
  font-weight: 500;
  font-size: 40px;
  flex-direction: row;
  color: #FFFFFF;
}

.header2 {
  font-family: Futura;
  font-weight: 100;
  font-size: 20px;
  flex-direction: row;
  color: #FFFFFF;
}

.ui.container2 {
  min-height: 100vh;
  max-width: 200vh;
  padding-left: 10vh;
  color: #00005A;
  padding-top: 100px;
}
.angled {
  display: flex;
  flex-wrap: wrap;
  padding-left: 20px;
  height: 15em;
  padding-top: 10px;
}

.header3 {
  font-size: 20px;
  flex-direction: row;
}

.header4 {
  font-size: 15px;
  flex-direction: row;
}

.ui.container3 {
  min-height: 60vh;
  padding-top: 10px;
}

.header5 {
  padding-top: 100px;
  padding-left: 300px;
  padding-bottom: 30px;
  background-color: #DBF3FA;
  color: #00005A;
  font-size: 30px;
}

.ui.stackable.borderless.menu2 {
  padding-top: 10px;
  padding-left: 10px;
  min-height: 10vh;
  background-color: #DBF3FA;
}

.Clogo {
  height: 7em;
}

.PGlogo {
  height: 7em;
}
.ui.vertically.divided.stackable.grid2 {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  padding-left: 250px;
  padding-top: 100px;
  min-height: 40vh;
  background-color: #18BCD7;
}

.ui.vertically.divided.stackable.grid3 {
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  padding-left: 200px;
  padding-top: 20px;
  min-height: 60vh;
  min-width: 200vh;
  background-color: #FFFFFF;
  color: #00005A;
}
.ui.vertically.divided.stackable.grid4 {
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  padding-left: 20px;
  padding-top: 100px;
  min-height: 50vh;
  background-color: #FFFFFF;
  color: #00005A;
}

.header6 {
  font-family: Futura-Bold;
  font-size: 50px;
  flex-direction: column;
  color: #FFFFFF;
}

.header7 {
  font-family: Futura;
  font-size: 20px;
  flex-direction: column;
  color: #FFFFFF;
}
.header8 {
  padding-top: 15px;
  margin: 130px;
}

.leaderboard {
  display: flex;
  flex-wrap: wrap;
  padding-left: 20px;
  height: 15em;
  padding-top: 10px;
}
.yearlytarget {
  display: flex;
  flex-wrap: wrap;
  padding-left: 20px;
  height: 20em;
  padding-top: 10px;
}
.ui.container4 {
  display: row;
  max-width: 250vh;
  padding-left: 5vh;
  min-height: 50vh;
  padding-top: 10px;
  background-color: #FFFFFF;
}

.ui.container5 {
  max-width: 300vh;
  padding-left: 5vh;
  min-height: 100vh;
  padding-top: 30px;
  background-color: #FFFFFF;
}

.ui.simple.dropdown.item1 {
  font-size: 25px;
  min-width: 45vh;
  padding-left: 100px;
  padding-top: 30px;
  padding-right: 50px;
  background-color: #2384D1;
  height: 3em;
  width: 12em;
  color: #FFFFFF;
  font-family: Futura;
}

.ui.simple.dropdown.item2 {
  font-size: 25px;
  min-width: 45vh;
  padding-left: 100px;
  padding-top: 30px;
  padding-right: 30px;
  background-color: #18BCD7;
  height: 3em;
  width: 12em;
  color: #FFFFFF;
  font-family: Futura;
}
.ui.simple.dropdown.item3 {
  font-size: 25px;
  min-width: 45vh;
  padding-left: 100px;
  padding-top: 30px;
  padding-right: 50px;
  background-color: #20D8B4;
  height: 3em;
  width: 12em;
  color: #FFFFFF;
  font-family: Futura;
}
.ui.simple.dropdown.item4 {
  font-size: 25px;
  min-width: 45vh;
  padding-left: 100px;
  padding-top: 30px;
  padding-right: 50px;
  background-color: #8DEACF;
  height: 3em;
  width: 12em;
  color: #FFFFFF;
  font-family: Futura;
}
.ui.simple.dropdown.item5 {
  font-size: 25px;
  min-width: 45vh;
  padding-left: 100px;
  padding-top: 30px;
  background-color: #FE9331;
  height: 3em;
  width: 12em;
  color: #FFFFFF;
  font-family: Futura;
}
.ui.simple.dropdown.item6 {
  font-size: 25px;
  min-width: 45vh;
  padding-left: 100px;
  padding-top: 30px;
  padding-right: 50px;
  background-color: #EB6890;
  height: 3em;
  width: 12em;
  color: #FFFFFF;
  font-family: Futura;
} */
